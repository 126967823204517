import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import * as d3 from 'd3';
import TokenBanner from './TrendingBanner';
import TokenChart from './TokenChart';
import Last10Transactions from './Last10Transactions';
import searchGlassIcon from './search_glass.png';
import InfoBox from './InfoBox';
import PortfolioTracker from './PortfolioTracker';
import VolumeCalculator from './VolumeCalculator';
import TokenDetails from './TokenDetails';
import KaspaPriceAndClock from './KaspaPriceAndClock';

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
};

const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

function App() {
  const [tokenData, setTokenData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedToken, setSelectedToken] = useState(null);
  const [marketCapUSD, setMarketCapUSD] = useState(null);
  const [oneHourVolumeUSD, setOneHourVolumeUSD] = useState(null);
  const [twentyFourHourVolumeUSD, setTwentyFourHourVolumeUSD] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [kaspaPrice, setKaspaPrice] = useState(null);
  const resultsContainerRef = useRef(null);

  const POLLING_INTERVAL = 120000; // 2 minutes

  useEffect(() => {
    const lastViewedToken = getCookie('lastViewedToken');
    if (lastViewedToken) {
      console.log('Last viewed token from cookie:', lastViewedToken);
      setSelectedToken({ 'Token Symbol': lastViewedToken });
    }

    const loadTokenInfoCSV = async () => {
      try {
        const filePath = '/csv/kasplex/tokeninfo.csv';
        const response = await fetch(filePath);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await d3.csv(response.url);
        setTokenData(data);

        if (lastViewedToken) {
          const tokenInfo = data.find(token => token['Token Symbol'] === lastViewedToken);
          if (tokenInfo) {
            console.log('Selected token info from CSV:', tokenInfo);
            setSelectedToken(tokenInfo);
            setMarketCapUSD(tokenInfo.mcap);
          }
        }
      } catch (error) {
        console.error('Error loading tokeninfo.csv:', error);
      }
    };

    const fetchKaspaPrice = async () => {
      try {
        const filePath = '/csv/kaspa_price.csv';
        const kaspaPriceResponse = await fetch(`${filePath}?${new Date().getTime()}`);

        if (!kaspaPriceResponse.ok) {
          throw new Error(`HTTP error! status: ${kaspaPriceResponse.status}`);
        }

        const kaspaPriceData = await kaspaPriceResponse.text();
        const parsedKaspaData = d3.csvParse(kaspaPriceData);
        const price = parseFloat(parsedKaspaData[0].price);

        if (!isNaN(price)) {
          setKaspaPrice(price);
        } else {
          console.warn("Kaspa price not found or invalid in the CSV file");
        }
      } catch (error) {
        console.error('Error fetching Kaspa price:', error);
      }
    };

    loadTokenInfoCSV();
    fetchKaspaPrice();

    const interval = setInterval(() => {
      loadTokenInfoCSV();
      fetchKaspaPrice();
    }, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsContainerRef.current && !resultsContainerRef.current.contains(event.target)) {
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedToken) {
      console.log('Currently selected token in App:', selectedToken['Token Symbol']);
      setCookie('lastViewedToken', selectedToken['Token Symbol'], 7);
      fetchTransactions();

      const interval = setInterval(() => {
        fetchTransactions();
      }, POLLING_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [selectedToken]);

  const fetchTransactions = async () => {
    if (selectedToken) {
      try {
        const filePath = `/csv/telebotprices/${selectedToken['Token Symbol']}.csv`;
        const data = await d3.csv(filePath);
        const timestamps = Object.keys(data[0]).slice(1);
        const krc20Amounts = data.find(row => row.metric === 'krc20_amount');
        const kasAmounts = data.find(row => row.metric === 'kas_amount');
        const pricePerUnitRow = data.find(row => row.metric === 'price_per_unit');

        if (krc20Amounts && kasAmounts && pricePerUnitRow) {
          const lastTransactions = timestamps.map((timestamp) => ({
            timestamp,
            krc20_amount: krc20Amounts[timestamp],
            kas_amount: kasAmounts[timestamp],
            price_per_unit: pricePerUnitRow[timestamp],
          }));

          const validTransactions = lastTransactions.filter(transaction =>
            !isNaN(parseFloat(transaction.krc20_amount)) &&
            !isNaN(parseFloat(transaction.kas_amount)) &&
            transaction.timestamp
          );

          setTransactions(validTransactions.slice(-100));
        } else {
          console.warn("Required transaction data not found in the CSV file");
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    }
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toUpperCase().slice(0, 10);
    setSearchTerm(input);
    setSelectedToken(null);
  };

  const handleTokenClick = (token) => {
    console.log(`You clicked on token: ${token.tick || token['Token Symbol']}`);
    
    const tokenSymbol = token.tick || token['Token Symbol'];
    const tokenInfo = tokenData.find(t => t['Token Symbol'] === tokenSymbol);
    
    if (tokenInfo) {
      setSelectedToken(tokenInfo);
      setSearchTerm('');  // Clear the search term after selecting the token
    }
    setCookie('lastViewedToken', tokenSymbol, 7);
  };

  const filteredTokens = searchTerm
    ? tokenData.filter(token =>
        token['Token Symbol']?.toUpperCase().includes(searchTerm)
      )
    : [];

  return (
    <div className="App">
      <TokenBanner onTokenClick={handleTokenClick} />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search Token..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
          maxLength={10}
          style={{
            backgroundImage: `url(${searchGlassIcon})`,
            backgroundPosition: '10px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '20px 20px',
          }}
        />
      </div>
     
      {filteredTokens.length > 0 && !selectedToken && (
        <div className="results-container" ref={resultsContainerRef}>
          {filteredTokens.map((token, index) => (
            <div
              key={index}
              className="token-result"
              onClick={() => handleTokenClick(token)}
              style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #ccc' }}
            >
              {token['Token Symbol']}
            </div>
          ))}
        </div>
      )}
       
      {selectedToken && (
        <>
          <InfoBox />
          <KaspaPriceAndClock />
          <PortfolioTracker currentToken={selectedToken} />
          <TokenDetails
            selectedToken={selectedToken}
            marketCapUSD={marketCapUSD}
            kaspaPrice={kaspaPrice}
            oneHourVolumeUSD={oneHourVolumeUSD}
            twentyFourHourVolumeUSD={twentyFourHourVolumeUSD}
          />
          <TokenChart token={selectedToken} setMarketCapUSD={setMarketCapUSD} kaspaPrice={kaspaPrice} />
          <Last10Transactions token={selectedToken['Token Symbol']} kaspaPrice={kaspaPrice} setTransactions={setTransactions} />

    
        </>
      )}

      <div className="footer-container">
        <p>
          Trade KRC20 here: 
          <a href="https://t.me/kspr_official/1" target="_blank" rel="noopener noreferrer" className="footer-link">
            t.me/kspr_official/1
          </a>
        </p>
        <p>
          Buy me a drink here cheers!
        </p>
        <p 
          className="kaspa-address" 
          onClick={() => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText('kaspa:qq6ha5n7fulh7twx38d2pvgdqznwt5y4hhz7t5rm99s5lmvdmncjy4jlhev3r')
                .then(() => {
                  alert('Kaspa address copied to clipboard!');
                })
                .catch(err => {
                  console.error('Failed to copy: ', err);
                });
            } else {
              alert('Clipboard API is not supported in this browser.');
            }
          }}
        >
          kaspa:qq6ha5n7fulh7twx38d2pvgdqznwt5y4hhz7t5rm99s5lmvdmncjy4jlhev3r
        </p>
      </div>
      <main>
        <div style={{ height: '60px' }}></div>
      </main>
    </div>
  );
}

export default App;
