import React, { useEffect, useState } from 'react';
import './TrendingBanner.css';
import * as d3 from 'd3';

const TokenBanner = ({ onTokenClick }) => {
  const [topTokens, setTopTokens] = useState([]);

  const loadTopTokens = async () => {
    try {
      const cacheBuster = new Date().getTime();
      console.log('Loading CSV data from csv/kasplex/top10.csv...');
      const data = await d3.csv(`csv/kasplex/top10.csv?${cacheBuster}`);

      console.log('Raw CSV data:', data);

      const tokens = data.map(d => {
        const change = parseFloat(d['Price Change (%)']);
        console.log(`Token: ${d.Token}, Change: ${change}`);
        return {
          tick: d.Token,
          change: isNaN(change) ? 0 : change 
        };
      });

      console.log('Processed tokens:', tokens);

      setTopTokens(tokens);
    } catch (error) {
      console.error('Error loading the CSV file:', error);
    }
  };

  useEffect(() => {
    loadTopTokens();

    const intervalId = setInterval(loadTopTokens, 600000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="banner-container">
      <div className="main-banner">
        <img src="/Kaspa_Fire.png" alt="Fire Logo" className="fire-logo" />
        <div className="scrolling-container">
          <div className="scrolling-text">
            {topTokens.map((token, index) => {
              const changeColor = token.change > 0 ? 'green' : 'red';
              const space = token.change === 0 ? ' ' : '';
              return (
                <div 
                  key={index} 
                  className="token"
                  onClick={() => onTokenClick(token)} // Handle token click
                  style={{ cursor: 'pointer' }} 
                >
                  <span style={{ color: 'lightgrey' }}>
                    {index + 1}. {token.tick}
                  </span>
                  <span style={{ color: changeColor }}>
                    {space}{token.change > 0 ? '+' : ''}{token.change.toFixed(2)}%
                  </span>
                  <span style={{ marginRight: '20px' }}></span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="advert-space">
        <p>Advertise Here</p>
      </div>
    </div>
  );
};

export default TokenBanner;
