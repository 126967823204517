import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

const TokenDetails = ({ selectedToken }) => {
  const [marketCapUSD, setMarketCapUSD] = useState(null);
  const [oneHourVolumeUSD, setOneHourVolumeUSD] = useState(null);
  const [twentyFourHourVolumeUSD, setTwentyFourHourVolumeUSD] = useState(null);
  const [mintLimit, setMintLimit] = useState(null);
  const [totalMinted, setTotalMinted] = useState(null);
  const [maxSupply, setMaxSupply] = useState(null);
  const [kaspaPrice, setKaspaPrice] = useState(null);
  const [latestFloorPrice, setLatestFloorPrice] = useState(null);


  const POLLING_INTERVAL = 120000; // 2 minutes

  // Function to fetch the Kaspa price
  const getKaspaPrice = async () => {
    try {
      const kaspaPriceFilePath = `/csv/kaspa_price.csv`;
      console.log('Fetching Kaspa price from:', kaspaPriceFilePath);
      const response = await fetch(kaspaPriceFilePath);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await d3.csv(response.url);
      const price = parseFloat(data[0].price);
      console.log('Fetched Kaspa price:', price);

      if (!isNaN(price)) {
        setKaspaPrice(price);
        return price;
      } else {
        console.error('Kaspa price is NaN');
        return null;
      }
    } catch (error) {
      console.error('Error fetching Kaspa price:', error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedToken && selectedToken['Token Symbol']) {
      console.log(`TokenDetails received token: ${selectedToken['Token Symbol']}`);
      const initialize = async () => {
        const price = await getKaspaPrice();
        if (price) {
          await fetchTokenDetails(selectedToken, price);
          await calculateVolumes(selectedToken['Token Symbol'], price);
        }
      };
      initialize();
  
      const intervalId = setInterval(initialize, POLLING_INTERVAL);
      return () => clearInterval(intervalId); // Cleanup on component unmount
    } else {
      console.warn('selectedToken is not defined or is missing a Token Symbol.');
    }
  }, [selectedToken]);

  const fetchTokenDetails = async (token, price) => {
    if (!token || !token['Token Symbol']) {
      console.error('Invalid token provided to fetchTokenDetails.');
      return;
    }

    try {
      const tokenInfoFilePath = `/csv/kasplex/tokeninfo.csv`;
      console.log('Fetching token info from:', tokenInfoFilePath);
      const tokenInfoResponse = await fetch(tokenInfoFilePath);

      if (!tokenInfoResponse.ok) {
        throw new Error(`HTTP error! status: ${tokenInfoResponse.status}`);
      }

      const tokenInfoData = await d3.csv(tokenInfoResponse.url);
      const tokenInfo = tokenInfoData.find(row => row['Token Symbol'] === token['Token Symbol']);

      if (!tokenInfo) {
        throw new Error(`Token ${token['Token Symbol']} not found in tokeninfo.csv`);
      }

      console.log('Token info:', tokenInfo);

      const dec = parseInt(tokenInfo['Decimal Places']);
      
      // Adjusting the Total Minted
      const minted = parseFloat(tokenInfo['Total Minted']);
      const actualMinted = minted / Math.pow(10, dec); // Convert to actual minted amount
      setTotalMinted(actualMinted.toLocaleString());

      // Debugging the minted value
      console.log(`Decimal Places: ${dec}, Total Minted: ${minted}, Actual Minted: ${actualMinted}`);

      // Adjusting the Mint Limit
      const mintLimit = parseFloat(tokenInfo['Mint Limit']) / Math.pow(10, dec); // Convert to actual mint limit
      setMintLimit(mintLimit.toLocaleString());

      // Adjusting the Max Supply
      const maxSupply = parseFloat(tokenInfo['Max Supply']) / Math.pow(10, dec); // Convert to actual max supply
      setMaxSupply(maxSupply.toLocaleString());

      // Fetch the floor price with cache busting
      const filePath = `/csv/telebotprices/${token['Token Symbol']}.csv?_=${new Date().getTime()}`;
      console.log('Fetching token details from:', filePath);
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await d3.csv(response.url);
      const pricePerUnitRow = data.find(row => row.metric === 'price_per_unit');

      if (pricePerUnitRow) {
        const mostRecentTimestamp = Object.keys(pricePerUnitRow).pop(); // Get the last timestamp
        const mostRecentFloorPrice = parseFloat(pricePerUnitRow[mostRecentTimestamp]);
        console.log('Most recent floor price:', mostRecentFloorPrice);

        if (!isNaN(mostRecentFloorPrice) && !isNaN(price) && !isNaN(actualMinted)) {
          const marketCapUSD = mostRecentFloorPrice * price * actualMinted;
          console.log('Market Cap Calculation:', {
            mostRecentFloorPrice,
            price,
            actualMinted,
            marketCapUSD
          });
          setMarketCapUSD(marketCapUSD);
          setLatestFloorPrice(mostRecentFloorPrice);
        } else {
          console.warn('One of the values for Market Cap calculation is NaN', {
            mostRecentFloorPrice,
            price,
            actualMinted
          });
        }
      } else {
        console.error('Price per unit data not found in CSV.');
      }
    } catch (error) {
      console.error('Error loading token details:', error);
    }
  };

  const calculateVolumes = async (selectedToken, price) => {
    if (!price) {
      console.error("Cannot calculate volumes, kaspaPrice is undefined");
      return;
    }

    const nowUTC = new Date(Date.now());
    const oneHourAgoUTC = new Date(nowUTC.getTime() - 60 * 60 * 1000);
    const twentyFourHoursAgoUTC = new Date(nowUTC.getTime() - 24 * 60 * 60 * 1000);

    try {
      const tokenCSVPath = `/csv/telebotprices/${selectedToken}.csv`;
      const response = await fetch(tokenCSVPath);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await d3.csv(tokenCSVPath);

      let oneHourVolume = 0;
      let twentyFourHourVolume = 0;

      const kasAmountsRow = data.find(row => row.metric === 'kas_amount');
      if (!kasAmountsRow) {
        console.error('kas_amount row not found in CSV data');
        return;
      }

      const timestampKeys = Object.keys(kasAmountsRow).filter(key => key !== 'metric');

      for (let timestampKey of timestampKeys) {
        const timestampUTC = new Date(`${timestampKey}Z`);

        if (isNaN(timestampUTC.getTime())) {
          console.error(`Invalid timestamp: ${timestampKey}`);
          continue;
        }

        const kasAmount = parseFloat(kasAmountsRow[timestampKey]);

        if (isNaN(kasAmount)) {
          console.error(`Invalid kas_amount at ${timestampKey}: ${kasAmount}`);
          continue;
        }

        if (timestampUTC >= oneHourAgoUTC && timestampUTC <= nowUTC) {
          oneHourVolume += kasAmount;
        }

        if (timestampUTC >= twentyFourHoursAgoUTC && timestampUTC <= nowUTC) {
          twentyFourHourVolume += kasAmount;
        }
      }

      const oneHourVolumeUSD = oneHourVolume * price;
      const twentyFourHourVolumeUSD = twentyFourHourVolume * price;

      console.log(`1H Volume in USD: ${oneHourVolumeUSD}`, { oneHourVolume, price });
      console.log(`24H Volume in USD: ${twentyFourHourVolumeUSD}`, { twentyFourHourVolume, price });

      setOneHourVolumeUSD(oneHourVolumeUSD);
      setTwentyFourHourVolumeUSD(twentyFourHourVolumeUSD);
    } catch (error) {
      console.error('Error fetching token volumes:', error);
    }
  };

  if (!selectedToken || !selectedToken['Token Symbol']) return null;

  return (
    <div className="token-details">
        <h2>
            {selectedToken['Token Symbol']} / KAS 
            {latestFloorPrice && (
                <div className="floor-price">
                    {latestFloorPrice.toFixed(8)}
                </div>
            )}
        </h2>
        {marketCapUSD && <p>Market Cap: ${parseFloat(marketCapUSD).toLocaleString()} USD</p>}
        {oneHourVolumeUSD !== null && <p>1H Volume: ${oneHourVolumeUSD.toLocaleString()} USD</p>}
        {twentyFourHourVolumeUSD !== null && <p>24H Volume: ${twentyFourHourVolumeUSD.toLocaleString()} USD</p>}
       
        <p>Total Minted: {totalMinted}</p>
        <p>Max Supply: {maxSupply}</p>
    </div>
);


};

export default TokenDetails;
