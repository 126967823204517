// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box {
  background-color: #1a1a1a;
  border: 1px solid #333333;
  padding: 10px;
  margin: 0px 0;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #999999;
  width: 89%;
  max-width: 950px;
  margin-top: 10px;
}

.info-box a {
  color: #007bff;
  text-decoration: none;
}

.info-box a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/InfoBox.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,0CAA0C;EAC1C,eAAe;EACf,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".info-box {\r\n  background-color: #1a1a1a;\r\n  border: 1px solid #333333;\r\n  padding: 10px;\r\n  margin: 0px 0;\r\n  border-radius: 5px;\r\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\r\n  font-size: 14px;\r\n  color: #999999;\r\n  width: 89%;\r\n  max-width: 950px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.info-box a {\r\n  color: #007bff;\r\n  text-decoration: none;\r\n}\r\n\r\n.info-box a:hover {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
