import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

const VolumeCalculator = ({ tokenTick, kaspaPrice, onVolumesCalculated }) => {
  const [oneHourVolumeUSD, setOneHourVolumeUSD] = useState(null);
  const [twentyFourHourVolumeUSD, setTwentyFourHourVolumeUSD] = useState(null);

  const nowUTC = new Date(Date.now());
  const oneHourAgoUTC = new Date(nowUTC.getTime() - 60 * 60 * 1000);
  const twentyFourHoursAgoUTC = new Date(nowUTC.getTime() - 24 * 60 * 60 * 1000);

  console.log(`Using current UTC time: ${nowUTC.toISOString()}`);
  console.log(`1 hour ago (UTC): ${oneHourAgoUTC.toISOString()}`);
  console.log(`24 hours ago (UTC): ${twentyFourHoursAgoUTC.toISOString()}`);

  useEffect(() => {
    const fetchTokenVolumes = async () => {
      try {
        console.log(`Fetching volume data for token: ${tokenTick}`);
        const tokenCSVPath = `/csv/telebotprices/${tokenTick}.csv`;
        const response = await fetch(tokenCSVPath);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await d3.csv(tokenCSVPath);
        console.log(`CSV data loaded. Total rows: ${data.length}`);

        let oneHourVolume = 0;
        let twentyFourHourVolume = 0;
        let oneHourTransactionCount = 0;

        // Access the kas_amount row correctly (Row 1)
        const kasAmounts = data[1]; // Row 1 for kas_amount
        const timestampKeys = Object.keys(data[0]).filter(key => key !== 'metric');

        console.log("Kas Amounts:", kasAmounts); // Log kas_amounts

        // Iterate over the timestamps to calculate volumes
        for (let timestampKey of timestampKeys) {
          const timestampUTC = new Date(`${timestampKey}Z`);

          if (isNaN(timestampUTC.getTime())) {
            console.error(`Invalid timestamp: ${timestampKey}`);
            continue;
          }

          const kasAmount = parseFloat(kasAmounts[timestampKey]);

          if (isNaN(kasAmount)) {
            console.error(`Invalid kas_amount at ${timestampKey}: ${kasAmount}`);
            continue;
          }

          if (timestampUTC >= oneHourAgoUTC && timestampUTC <= nowUTC) {
            oneHourVolume += kasAmount;
            oneHourTransactionCount += 1;

            console.log(`Transaction at ${timestampUTC.toISOString()} identified within the last hour. Kas amount: ${kasAmount}`);
          }

          if (timestampUTC >= twentyFourHoursAgoUTC && timestampUTC <= nowUTC) {
            twentyFourHourVolume += kasAmount;
          }
        }

        const oneHourVolumeUSD = oneHourVolume * kaspaPrice * 2; // Multiply by 2
        const twentyFourHourVolumeUSD = twentyFourHourVolume * kaspaPrice * 2; // Multiply by 2

        console.log(`1H Volume in USD: ${oneHourVolumeUSD}`);
        console.log(`24H Volume in USD: ${twentyFourHourVolumeUSD}`);
        console.log(`Identified ${oneHourTransactionCount} transactions within the last hour.`);
        console.log(`Total Kaspa spent in the last hour: ${oneHourVolume}`);

        setOneHourVolumeUSD(oneHourVolumeUSD);
        setTwentyFourHourVolumeUSD(twentyFourHourVolumeUSD);
        onVolumesCalculated(oneHourVolumeUSD, twentyFourHourVolumeUSD);
      } catch (error) {
        console.error('Error fetching token volumes:', error);
      }
    };

    if (tokenTick && kaspaPrice) {
      fetchTokenVolumes();
    }
  }, [tokenTick, kaspaPrice, onVolumesCalculated]);

  return null;
};

export default VolumeCalculator;
