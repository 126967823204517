// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kaspa-price-clock-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 89%;
    max-width: 950px;
    margin: 0px 0;
    margin-top: 10px;
    padding: 10px;
    background-color: #1b1b1b;
    border-radius: 5px;
    color: #bbbbbb;
    border: solid rgba(51, 51, 51, 0.521) 1px;
    font-size: 8px;
  }
  
  .kaspa-price {
    font-size: 24px;
    font-weight: 600;
  }
  
  .utc-clock {
    font-size: 20px;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/KaspaPriceAndClock.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,yCAAyC;IACzC,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".kaspa-price-clock-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 89%;\r\n    max-width: 950px;\r\n    margin: 0px 0;\r\n    margin-top: 10px;\r\n    padding: 10px;\r\n    background-color: #1b1b1b;\r\n    border-radius: 5px;\r\n    color: #bbbbbb;\r\n    border: solid rgba(51, 51, 51, 0.521) 1px;\r\n    font-size: 8px;\r\n  }\r\n  \r\n  .kaspa-price {\r\n    font-size: 24px;\r\n    font-weight: 600;\r\n  }\r\n  \r\n  .utc-clock {\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
