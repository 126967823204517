import React, { useState, useEffect } from 'react';
import './PortfolioTracker.css';

const PortfolioTracker = ({ currentToken }) => {
    const [isOpen, setIsOpen] = useState(() => {
        const savedState = localStorage.getItem('portfolioTrackerState');
        return savedState ? JSON.parse(savedState) : false;
    });
    const [portfolio, setPortfolio] = useState(() => {
        const savedPortfolio = localStorage.getItem('portfolio');
        return savedPortfolio ? JSON.parse(savedPortfolio) : {};
    });
    const [tokenAmount, setTokenAmount] = useState(0);
    const [kaspaPrice, setKaspaPrice] = useState(null);
    const [prices, setPrices] = useState({});
    const [totalPortfolioValueUSD, setTotalPortfolioValueUSD] = useState(0);
    const [totalPortfolioValueKAS, setTotalPortfolioValueKAS] = useState(0);

    const toggleCollapse = () => {
        setIsOpen((prevState) => {
            const newState = !prevState;
            localStorage.setItem('portfolioTrackerState', JSON.stringify(newState));
            return newState;
        });
    };

    useEffect(() => {
        if (currentToken) {
            const amount = portfolio[currentToken['Token Symbol']]?.amount || 0;
            setTokenAmount(amount);
        }
    }, [currentToken, portfolio]);

    const fetchKaspaPrice = async () => {
        try {
            const timestamp = new Date().getTime(); // Cache busting parameter
            const filePath = `/csv/kaspa_price.csv?_=${timestamp}`;
            const response = await fetch(filePath);
            const data = await response.text();
            const parsedData = data.split('\n').map(row => row.split(','));
    
            // Adjust the index here depending on the CSV file structure
            const price = parseFloat(parsedData[1][0]);  // Adjusted to correctly reference the price
            console.log('Kaspa Price:', price);  // Debugging
            setKaspaPrice(price);
        } catch (error) {
            console.error('Error fetching Kaspa price:', error);
        }
    };

    const fetchTokenData = async (tick) => {
        try {
            const timestamp = new Date().getTime(); // Cache busting parameter
            const filePath = `/csv/telebotprices/${tick}.csv?_=${timestamp}`;
            const response = await fetch(filePath);
            const data = await response.text();
            const parsedData = data.split('\n').map(row => row.split(','));
            
            // Fetching the last available price per unit from the CSV
            const pricePerUnitRow = parsedData.find(row => row[0] === 'price_per_unit');
            if (pricePerUnitRow) {
                // Assuming the latest value is the last column
                const latestFloorPrice = parseFloat(pricePerUnitRow[pricePerUnitRow.length - 1]);
                console.log(`Token: ${tick}, Floor Price: ${latestFloorPrice}`);  // Debugging
                return { floorPrice: latestFloorPrice };
            } else {
                console.warn(`Floor price not found for ${tick}`);
                return { floorPrice: null };
            }
        } catch (error) {
            console.error(`Error fetching data for ${tick}:`, error);
            return { floorPrice: null };
        }
    };

    const fetchPrices = async () => {
        await fetchKaspaPrice();
    
        const newPrices = {};
        for (const tick of Object.keys(portfolio)) {
            const { floorPrice } = await fetchTokenData(tick);
            if (floorPrice !== null) {
                newPrices[tick] = { floorPrice };
            } else {
                console.warn(`Price data missing for ${tick}`);
            }
        }
        console.log('Prices fetched:', newPrices);  // Debugging
        setPrices(newPrices);
    };

    useEffect(() => {
        // Initial fetch
        fetchPrices();

        // Polling every 5 minutes
        const intervalId = setInterval(fetchPrices, 300000); // 300000 ms = 5 minutes

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [portfolio]);

    const calculateTotalValue = (tick, amount) => {
        const tokenData = prices[tick];
        if (tokenData && kaspaPrice) {
            const valueInKAS = tokenData.floorPrice * amount;
            const valueInUSD = valueInKAS * kaspaPrice;
            console.log(`Token: ${tick}, Value per Token in KAS: ${tokenData.floorPrice}, Amount: ${amount}`);  // Debugging
            return { valueInUSD, valueInKAS };
        }
        return { valueInUSD: 0, valueInKAS: 0 }; // If prices are not available, return 0
    };

    useEffect(() => {
        let totalUSD = 0;
        let totalKAS = 0;

        Object.entries(portfolio).forEach(([tick, { amount }]) => {
            const { valueInUSD, valueInKAS } = calculateTotalValue(tick, amount);
            totalUSD += valueInUSD;
            totalKAS += valueInKAS;
        });

        console.log('Total Portfolio Value in USD:', totalUSD);  // Debugging
        console.log('Total Portfolio Value in KAS:', totalKAS);  // Debugging

        setTotalPortfolioValueUSD(totalUSD);
        setTotalPortfolioValueKAS(totalKAS);
    }, [prices, portfolio, kaspaPrice]);

    const handleAddRemoveToken = () => {
        const newPortfolio = { ...portfolio };
        if (newPortfolio[currentToken['Token Symbol']]) {
            delete newPortfolio[currentToken['Token Symbol']];
        } else {
            newPortfolio[currentToken['Token Symbol']] = { amount: tokenAmount };
        }

        setPortfolio(newPortfolio);
        localStorage.setItem('portfolio', JSON.stringify(newPortfolio));
        setTokenAmount(0);
    };

    const handleAmountChange = (event) => {
        setTokenAmount(event.target.value);
    };

    return (
        <div className="portfolio-tracker">
            <h2
                onClick={toggleCollapse}
                className={`collapsible-title ${isOpen ? '' : 'collapsed'}`}
            >
                Portfolio Tracker
            </h2>
            {isOpen && (
                <div className="portfolio-content">
                    <h4>Total Portfolio Value: ${totalPortfolioValueUSD.toFixed(2)} / k{totalPortfolioValueKAS.toFixed(2)}</h4>
                    {currentToken ? (
                        <div>
                            <button onClick={handleAddRemoveToken}>
                                {portfolio[currentToken['Token Symbol']] ? `Remove ${currentToken['Token Symbol']} from Portfolio` : `Add ${currentToken['Token Symbol']} to Portfolio`}
                            </button>
                            <input
                                type="number"
                                value={tokenAmount}
                                onChange={handleAmountChange}
                                placeholder="Enter amount"
                            />
                            <button onClick={() => {
                                if (portfolio[currentToken['Token Symbol']]) {
                                    portfolio[currentToken['Token Symbol']].amount = tokenAmount;
                                }
                                setPortfolio({ ...portfolio });
                                localStorage.setItem('portfolio', JSON.stringify(portfolio));
                            }}>
                                Adjust
                            </button>
                        </div>
                    ) : (
                        <p>No token selected.</p>
                    )}
                    <div>
                        <h4>Your Portfolio:</h4>
                        {Object.entries(portfolio).map(([tick, { amount }]) => {
                            const { valueInUSD, valueInKAS } = calculateTotalValue(tick, amount);
                            return (
                                <p key={tick}>
                                    {tick}: {amount}  = ${valueInUSD.toFixed(2)} / k{valueInKAS.toFixed(2)}
                                </p>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PortfolioTracker;
