import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import './chart_create.css'; // Import the CSS file

const TokenChart = ({ token, setMarketCapUSD, cacheBuster }) => {
  const [marketCaps, setMarketCaps] = useState([]);
  const [chartAvailable, setChartAvailable] = useState(true);
  const [minMarketCap, setMinMarketCap] = useState(null);
  const [maxMarketCap, setMaxMarketCap] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('All');
  

  const POLLING_INTERVAL = 120000;

  useEffect(() => {
    const fetchData = async () => {
      console.log(`TokenChart: Fetching data for token: ${token['Token Symbol']}`);

      try {
        const fetchedMinted = await findTickerInTokenInfo(token['Token Symbol']);
        console.log(`TokenChart: Fetched minted tokens: ${fetchedMinted}`);

        const fetchedKaspaPrice = await fetchKaspaPrice();
        console.log(`TokenChart: Fetched Kaspa price: ${fetchedKaspaPrice}`);

        const fetchedFloorPrices = await fetchTokenData(token['Token Symbol']);
        console.log(`TokenChart: Fetched floor prices: ${fetchedFloorPrices.length} data points`);

        if (fetchedMinted && fetchedKaspaPrice && fetchedFloorPrices.length > 0) {
          let prevMcap = null;

          const newMarketCaps = fetchedFloorPrices.map((fp, index, arr) => {
            const mcap = fetchedMinted * (fp.floorPrice * fetchedKaspaPrice);
            let useMcap = mcap;

            if (prevMcap !== null) {
              const deviation = Math.abs((mcap - prevMcap) / prevMcap);
              const nextMcap = index < arr.length - 1 
                ? fetchedMinted * (arr[index + 1].floorPrice * fetchedKaspaPrice)
                : null;

              if (deviation > 0.3 && nextMcap !== null && Math.abs((mcap - nextMcap) / nextMcap) > 0.3) {
                console.warn(`TokenChart: Detected a deviation of more than 30% at index ${index}. Replacing with previous value.`);
                useMcap = prevMcap;
              }
            }

            prevMcap = useMcap;
            return { timestamp: fp.timestamp, mcap: useMcap };
          });

          console.log(`TokenChart: Processed market caps: ${newMarketCaps.length} data points`);
          setMarketCaps(newMarketCaps);
          setChartAvailable(true);
          
          const marketCapValues = newMarketCaps.map((data) => data.mcap);
          setMinMarketCap(Math.min(...marketCapValues));
          setMaxMarketCap(Math.max(...marketCapValues));
          console.log(`TokenChart: Min market cap: ${Math.min(...marketCapValues)}, Max market cap: ${Math.max(...marketCapValues)}`);
        } else {
          setChartAvailable(false);
          console.warn('TokenChart: No market cap data available.');
        }
      } catch (error) {
        console.error('TokenChart: Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [token]);

  const getFilteredData = () => {
    console.log(`TokenChart: Filtering data for timeframe: ${selectedTimeFrame}`);

    const nowUTC = new Date().toISOString();  // Get the current UTC time as ISO string
    let timeLimitUTC;

    switch (selectedTimeFrame) {
      case '1h':
        timeLimitUTC = new Date(new Date(nowUTC).getTime() - 1 * 60 * 60 * 1000).toISOString(); // 1 hour ago in UTC
        break;
      case '3h':
        timeLimitUTC = new Date(new Date(nowUTC).getTime() - 3 * 60 * 60 * 1000).toISOString(); // 3 hours ago in UTC
        break;
      case '12h':
        timeLimitUTC = new Date(new Date(nowUTC).getTime() - 12 * 60 * 60 * 1000).toISOString(); // 12 hours ago in UTC
        break;
      case '1d':
        timeLimitUTC = new Date(new Date(nowUTC).getTime() - 24 * 60 * 60 * 1000).toISOString(); // 1 day ago in UTC
        break;
      case '1w':
        timeLimitUTC = new Date(new Date(nowUTC).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString(); // 1 week ago in UTC
        break;
      default:
        return marketCaps;
    }

    console.log(`TokenChart: Time limit set to: ${timeLimitUTC} (UTC)`);

    // Filter the data points that fall within the selected timeframe
    const filteredData = marketCaps.filter(dataPoint => {
      const dataPointTime = new Date(`${dataPoint.timestamp}Z`).toISOString(); // Ensure timestamp is UTC
      const isWithinTimeframe = dataPointTime >= timeLimitUTC;
      console.log(`TokenChart: Checking data point timestamp: ${dataPoint.timestamp} (UTC) | Is within timeframe: ${isWithinTimeframe}`);
      return isWithinTimeframe;
    });

    console.log(`TokenChart: Filtered data: ${filteredData.length} data points found within the timeframe`);

    if (filteredData.length < 2) {
      const lastKnownData = marketCaps[marketCaps.length - 1];
      console.warn('TokenChart: Insufficient data points in the selected timeframe. Creating dummy points.');

      if (filteredData.length === 0) {
        filteredData.push({
          timestamp: timeLimitUTC,
          mcap: lastKnownData.mcap,
        }, {
          timestamp: nowUTC,
          mcap: lastKnownData.mcap,
        });
        console.log('TokenChart: No data within timeframe. Dummy points created.');
      } else if (filteredData.length === 1) {
        filteredData.push({
          timestamp: nowUTC,
          mcap: filteredData[0].mcap,
        });
        console.log('TokenChart: Only one data point found. Adding a dummy point for continuity.');
      }
    }

    return filteredData;
  };

  const findTickerInTokenInfo = async (tick) => {
    try {
      const tokenInfoFilePath = `/csv/kasplex/tokeninfo.csv?${new Date().getTime()}`;
      const tokenInfoResponse = await fetch(tokenInfoFilePath, { cache: 'no-store' });

      if (!tokenInfoResponse.ok) {
        throw new Error(`TokenChart: HTTP error! status: ${tokenInfoResponse.status}`);
      }

      const tokenInfoData = await tokenInfoResponse.text();
      const parsedTokenInfo = parseCSV(tokenInfoData);

      const tickerRow = parsedTokenInfo.find(row => row['Token Symbol'] === tick);

      if (tickerRow) {
        const totalMinted = parseFloat(tickerRow['Total Minted']);
        const decimalPlaces = parseInt(tickerRow['Decimal Places']);
        return totalMinted / Math.pow(10, decimalPlaces);
      } else {
        console.warn(`TokenChart: Ticker ${tick} not found in tokeninfo.csv.`);
        setChartAvailable(false);
        return null;
      }
    } catch (error) {
      console.error('TokenChart: Error loading or parsing tokeninfo.csv:', error);
      setChartAvailable(false);
      return null;
    }
  };

  const fetchKaspaPrice = async () => {
    try {
      const kaspaPriceResponse = await fetch(`/csv/kaspa_price.csv?${new Date().getTime()}`, { cache: 'no-store' });

      if (!kaspaPriceResponse.ok) {
        throw new Error(`TokenChart: HTTP error! status: ${kaspaPriceResponse.status}`);
      }

      const kaspaPriceData = await kaspaPriceResponse.text();
      const parsedKaspaData = parseCSV(kaspaPriceData);

      if (parsedKaspaData.length && parsedKaspaData[0].price) {
        return parseFloat(parsedKaspaData[0].price);
      } else {
        console.error('TokenChart: KASPA price data is missing or not in the expected format.');
        setChartAvailable(false);
        return null;
      }
    } catch (error) {
      console.error('TokenChart: Error loading KASPA price:', error);
      setChartAvailable(false);
      return null;
    }
  };

  const fetchTokenData = async (token) => {
    try {
      const filePath = `/csv/telebotprices/${token}.csv?`;
      const response = await fetch(filePath, { cache: 'no-store' });

      if (!response.ok) {
        throw new Error(`TokenChart: HTTP error! status: ${response.status}`);
      }

      const data = await response.text();

      if (data.startsWith('<!DOCTYPE html>')) {
        console.error('TokenChart: Loaded data is HTML, not CSV. Please check the file path.');
        setChartAvailable(false);
        return [];
      }

      const parsedData = parseCSV(data);
      const pricePerUnitRow = parsedData.find(row => row.metric === 'price_per_unit');

      if (pricePerUnitRow) {
        return Object.keys(pricePerUnitRow).filter(key => key !== 'metric').map(key => ({
          timestamp: key,
          floorPrice: parseFloat(pricePerUnitRow[key]),
        })).filter(fp => !isNaN(fp.floorPrice));
      } else {
        console.error('TokenChart: Price per unit row not found in parsed data.');
        setChartAvailable(false);
        return [];
      }
    } catch (error) {
      console.error('TokenChart: Error loading token data:', error);
      setChartAvailable(false);
      return [];
    }
  };

  const parseCSV = (data) => {
    const rows = data.split('\n');
    const headers = rows[0].split(',');

    return rows.slice(1).map(row => {
      const values = row.split(',');
      return headers.reduce((acc, header, i) => {
        acc[header] = values[i];
        return acc;
      }, {});
    });
  };

  return (
    <div id="chart-container" className="dark-theme">
      <div className="timeframe-buttons">
        {['1h', '3h', '12h', '1d', '1w', 'All'].map(frame => (
          <button
            key={frame}
            onClick={() => {
              console.log(`TokenChart: Timeframe selected: ${frame}`);
              setSelectedTimeFrame(frame);
            }}
            className={selectedTimeFrame === frame ? 'active' : ''}
          >
            {frame}
          </button>
        ))}
      </div>

      {marketCaps.length < 2 ? (
        <p className="no-data">Sorry, no chart available for this token yet.</p>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={getFilteredData()}
            margin={{ top: 0, right: -6, left: -6, bottom: 60 }}  // Adjusted margins to prevent cutting off
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              stroke="#bbb"
              tick={{ angle: -45, textAnchor: 'end' }} // Rotate labels and anchor them
              interval="preserveStartEnd"  // Only display labels at start and end
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tickFormatter={value => `$${(value / 1e6).toFixed(2)}m`}
              stroke="#bbb"
              domain={[minMarketCap * 0.95, maxMarketCap * 1.05]}  // Adjust domain for better focus
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={value => `$${(value / 1e6).toFixed(2)}m`}
              stroke="#bbb"
              domain={[minMarketCap * 0.95, maxMarketCap * 1.05]}  // Adjust domain for better focus
            />
            <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#eee' }} />
            <Line
              type="monotone"
              dataKey="mcap"
              stroke="#8884d8"
              yAxisId="left"
              strokeWidth={1}
              dot={false}  // Removed the circles on the line
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default TokenChart;
