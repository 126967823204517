import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import './KaspaPriceAndClock.css';

const KaspaPriceAndClock = () => {
  const [kaspaPrice, setKaspaPrice] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date().toUTCString());

  const fetchKaspaPrice = async () => {
    try {
      const filePath = '/csv/kaspa_price.csv';
      const response = await fetch(`${filePath}?${new Date().getTime()}`); // Cache busting

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const kaspaPriceData = await response.text();
      const parsedKaspaData = d3.csvParse(kaspaPriceData);
      const price = parseFloat(parsedKaspaData[0].price);

      if (!isNaN(price)) {
        setKaspaPrice(price);
      } else {
        console.warn("Kaspa price not found or invalid in the CSV file");
      }
    } catch (error) {
      console.error('Error fetching Kaspa price:', error);
    }
  };

  useEffect(() => {
    fetchKaspaPrice();
    const priceInterval = setInterval(fetchKaspaPrice, 300000); // Update every 5 minutes
    const clockInterval = setInterval(() => setCurrentTime(new Date().toUTCString()), 1000); // Update clock every second

    return () => {
      clearInterval(priceInterval);
      clearInterval(clockInterval);
    };
  }, []);

  return (
    <div className="kaspa-price-clock-container">
      <div className="kaspa-price">
        Kaspa Price: <span style={{ color: 'green' }}>${kaspaPrice ? kaspaPrice.toFixed(4) : 'Loading...'}</span>
      </div>
      <div className="utc-clock">
        {currentTime}
      </div>
    </div>
  );
};

export default KaspaPriceAndClock;
