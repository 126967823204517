import React from 'react';
import ReactDOM from 'react-dom/client'; // Make sure you're importing from 'react-dom/client'
import App from './App';
import './index.css';

// Remove the old ReactDOM.render
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
