import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import './Last10Transactions.css';

const POLLING_INTERVAL = 120000; // Poll every 2 minutes (120,000 ms)

const Last10Transactions = ({ token }) => {
  const [transactions, setTransactions] = useState([]);
  const [kaspaPrice, setKaspaPrice] = useState(null);

  const fetchKaspaPrice = async () => {
    try {
      // Load Kaspa price from public/csv/kaspa_price.csv with cache buster
      const filePath = `/csv/kaspa_price.csv?${new Date().getTime()}`;
      const kaspaPriceResponse = await fetch(filePath, { cache: 'no-store' });

      if (!kaspaPriceResponse.ok) {
        throw new Error(`HTTP error! status: ${kaspaPriceResponse.status}`);
      }

      const kaspaPriceData = await kaspaPriceResponse.text();
      const parsedKaspaData = d3.csvParse(kaspaPriceData);
      const price = parseFloat(parsedKaspaData[0].price);

      if (!isNaN(price)) {
        setKaspaPrice(price);
      } else {
        console.warn("Kaspa price not found or invalid in the CSV file");
      }
    } catch (error) {
      console.error('Error fetching Kaspa price:', error);
    }
  };

  const fetchTransactions = async () => {
    try {
      // Load transactions with cache buster
      const filePath = `/csv/telebotprices/${token}.csv?${new Date().getTime()}`;
      const data = await d3.csv(filePath);

      const timestamps = Object.keys(data[0]).slice(1); // Extract timestamps
      const krc20Amounts = data.find(row => row.metric === 'krc20_amount');
      const kasAmounts = data.find(row => row.metric === 'kas_amount');
      const pricePerUnitRow = data.find(row => row.metric === 'price_per_unit');

      if (krc20Amounts && kasAmounts && pricePerUnitRow) {
        const lastTransactions = timestamps.map((timestamp, index) => ({
          timestamp,
          krc20_amount: krc20Amounts[timestamp],
          kas_amount: kasAmounts[timestamp],
          price_per_unit: pricePerUnitRow[timestamp],
        }));

        // Filter out invalid transactions
        const validTransactions = lastTransactions.filter(transaction =>
          !isNaN(parseFloat(transaction.krc20_amount)) &&
          !isNaN(parseFloat(transaction.kas_amount)) &&
          transaction.timestamp // Ensure the timestamp is valid
        );

        setTransactions(validTransactions.slice(-100)); // Limit to the last 100 transactions
      } else {
        console.warn("Required transaction data not found in the CSV file");
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    fetchKaspaPrice();
    fetchTransactions();

    const interval = setInterval(() => {
      fetchKaspaPrice();
      fetchTransactions();
    }, POLLING_INTERVAL);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [token]);

  return (
    <div className="last10-container">
      <h3 className="sticky-title">Last 100 Transactions</h3>
      <div className="scrollable-transactions">
        {transactions.length > 0 ? (
          <table className="transaction-table">
            <thead className="sticky-header">
              <tr>
                <th>Timestamp</th>
                <th>{`${token} Amount`}</th>
                <th>KAS Amount</th>
                <th>Price per Unit (KAS)</th>
                <th>Value (USD)</th>
              </tr>
            </thead>
            <tbody>
              {transactions.slice().reverse().map((transaction, index) => {
                const kasAmount = parseFloat(transaction.kas_amount);
                const valueInUSD = !isNaN(kasAmount) && kaspaPrice
                  ? (kasAmount * kaspaPrice).toFixed(2)
                  : 'N/A';

                return (
                  <tr key={index}>
                    <td>{transaction.timestamp}</td>
                    <td>{transaction.krc20_amount}</td>
                    <td>{transaction.kas_amount}</td>
                    <td>{transaction.price_per_unit}</td>
                    <td>${valueInUSD}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No transactions available</p>
        )}
      </div>
    </div>
  );
};

export default Last10Transactions;
