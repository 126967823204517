import React from 'react';
import './InfoBox.css'; // Create a CSS file for styling the InfoBox

const InfoBox = () => {
  return (
    <div className="info-box">
      <p>
        I am aware of bugs, I am working on stuff, To report bugs, join the 
        <a href="https://discord.gg/nachothekat" target="_blank" rel="noopener noreferrer"> NACHO Discord server</a>.
        My Kaspa wallet is in the footer if you like the tool and want to buy me a drink, Servers aint free bruh.
        
         P.s to add a
        token to the tracker navigate to the token 1st and press the add token button, then adjust the amount of tokens !
      </p>
    </div>
  );
};

export default InfoBox;
