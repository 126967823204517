// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TokenBanner.css */

.banner-container {
    /* Your styles for the banner container */
  }
  
  .main-banner {
    /* Your styles for the main banner */
  }
  
  .fire-logo {
    /* Your styles for the fire logo */
  }
  
  .scrolling-container {
    /* Your styles for the scrolling container */
  }
  
  .scrolling-text {
    /* Your styles for the scrolling text */
  }
  
  .token {
    /* Your styles for each token */
  }
  
`, "",{"version":3,"sources":["webpack://./src/TrendingBanner.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,yCAAyC;EAC3C;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,4CAA4C;EAC9C;;EAEA;IACE,uCAAuC;EACzC;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":["/* TokenBanner.css */\r\n\r\n.banner-container {\r\n    /* Your styles for the banner container */\r\n  }\r\n  \r\n  .main-banner {\r\n    /* Your styles for the main banner */\r\n  }\r\n  \r\n  .fire-logo {\r\n    /* Your styles for the fire logo */\r\n  }\r\n  \r\n  .scrolling-container {\r\n    /* Your styles for the scrolling container */\r\n  }\r\n  \r\n  .scrolling-text {\r\n    /* Your styles for the scrolling text */\r\n  }\r\n  \r\n  .token {\r\n    /* Your styles for each token */\r\n  }\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
